import { createSlice } from '@reduxjs/toolkit';

const LOCAL_STORAGE_FAVORITES = 'BARANSKI_PRODUCTS_FAVORITES';

let initialFavoritesState = { favoriteDoors: {}, favoriteAccesories: {} };
if (typeof window !== 'undefined') {
    initialFavoritesState = {
        favoriteDoors: JSON.parse(localStorage.getItem(LOCAL_STORAGE_FAVORITES))?.favoriteDoors || {},
        favoriteAccesories: JSON.parse(localStorage.getItem(LOCAL_STORAGE_FAVORITES))?.favoriteAccesories || {}
    };
}

export const favoritesSlice = createSlice({
    name: 'favorites',
    initialState: initialFavoritesState,
    reducers: {
        setFavoriteAccesory: (state, action) => {
            if (action.payload in state.favoriteAccesories) {
                const { [action.payload]: value, ...rest } = state.favoriteAccesories;
                // eslint-disable-next-line no-param-reassign
                state.favoriteAccesories = rest;
            } else {
                // eslint-disable-next-line no-param-reassign
                state.favoriteAccesories = { ...state.favoriteAccesories, [action.payload]: true };
            }

            localStorage.setItem(LOCAL_STORAGE_FAVORITES, JSON.stringify(state));
        },
        setFavoriteDoor: (state, action) => {
            if (action.payload in state.favoriteDoors) {
                const { [action.payload]: value, ...rest } = state.favoriteDoors;
                // eslint-disable-next-line no-param-reassign
                state.favoriteDoors = rest;
            } else {
                // eslint-disable-next-line no-param-reassign
                state.favoriteDoors = { ...state.favoriteDoors, [action.payload]: true };
            }

            localStorage.setItem(LOCAL_STORAGE_FAVORITES, JSON.stringify(state));
        }
    }
});

// actions export
export const { setFavoriteAccesory, setFavoriteDoor } = favoritesSlice.actions;
// state export
export const selectFavorites = (state) => state.favorites;
// exporting the reducer here, as we need to add this to the store
export default favoritesSlice.reducer;