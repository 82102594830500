import { createSlice } from '@reduxjs/toolkit';

let initialFiltersVisibilityState = false;

export const filtersVisibility = createSlice({
    name: 'filtersVisibility',
    initialState: initialFiltersVisibilityState,
    reducers: {
        setVisibility: (state, action) => {
            if (action.payload !== undefined) {
                return action.payload;
            }
            return !state;
        }
    }
});

// actions export
export const { setVisibility } = filtersVisibility.actions;
// state export
export const selectVisibility = (state) => state.filtersVisibility;
// exporting the reducer here, as we need to add this to the store
export default filtersVisibility.reducer;