import React, { createContext } from 'react';
import { CookieConsent } from 'utilities/components/cookie-consent/component/index';
import PropTypes from 'prop-types';

import { store } from 'utilities/redux/store';
import { Provider } from 'react-redux';

export const FavoritesContext = createContext();

export const AppProvider = ({ children }) => (
    <CookieConsent>
        <Provider store={ store }>
            {children}
        </Provider>
    </CookieConsent>
);

AppProvider.propTypes = {
    children: PropTypes.node.isRequired
};