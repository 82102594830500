// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-akcesorium-jsx": () => import("./../../../src/pages/akcesorium.jsx" /* webpackChunkName: "component---src-pages-akcesorium-jsx" */),
  "component---src-pages-artykul-jsx": () => import("./../../../src/pages/artykul.jsx" /* webpackChunkName: "component---src-pages-artykul-jsx" */),
  "component---src-pages-artykuly-jsx": () => import("./../../../src/pages/artykuly.jsx" /* webpackChunkName: "component---src-pages-artykuly-jsx" */),
  "component---src-pages-certyfikaty-jsx": () => import("./../../../src/pages/certyfikaty.jsx" /* webpackChunkName: "component---src-pages-certyfikaty-jsx" */),
  "component---src-pages-do-pobrania-jsx": () => import("./../../../src/pages/do-pobrania.jsx" /* webpackChunkName: "component---src-pages-do-pobrania-jsx" */),
  "component---src-pages-drzwi-jsx": () => import("./../../../src/pages/drzwi.jsx" /* webpackChunkName: "component---src-pages-drzwi-jsx" */),
  "component---src-pages-drzwi-na-wymiar-jsx": () => import("./../../../src/pages/drzwi-na-wymiar.jsx" /* webpackChunkName: "component---src-pages-drzwi-na-wymiar-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-gdzie-kupic-jsx": () => import("./../../../src/pages/gdzie-kupic.jsx" /* webpackChunkName: "component---src-pages-gdzie-kupic-jsx" */),
  "component---src-pages-historia-jsx": () => import("./../../../src/pages/historia.jsx" /* webpackChunkName: "component---src-pages-historia-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-instagram-feed-jsx": () => import("./../../../src/pages/instagramFeed.jsx" /* webpackChunkName: "component---src-pages-instagram-feed-jsx" */),
  "component---src-pages-inwestycje-jsx": () => import("./../../../src/pages/inwestycje.jsx" /* webpackChunkName: "component---src-pages-inwestycje-jsx" */),
  "component---src-pages-jakosc-jsx": () => import("./../../../src/pages/jakosc.jsx" /* webpackChunkName: "component---src-pages-jakosc-jsx" */),
  "component---src-pages-katalogi-jsx": () => import("./../../../src/pages/katalogi.jsx" /* webpackChunkName: "component---src-pages-katalogi-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-optimo-jsx": () => import("./../../../src/pages/optimo.jsx" /* webpackChunkName: "component---src-pages-optimo-jsx" */),
  "component---src-pages-premium-jsx": () => import("./../../../src/pages/premium.jsx" /* webpackChunkName: "component---src-pages-premium-jsx" */),
  "component---src-pages-produkty-jsx": () => import("./../../../src/pages/produkty.jsx" /* webpackChunkName: "component---src-pages-produkty-jsx" */),
  "component---src-pages-realizacje-jsx": () => import("./../../../src/pages/realizacje.jsx" /* webpackChunkName: "component---src-pages-realizacje-jsx" */),
  "component---src-pages-reklamacje-jsx": () => import("./../../../src/pages/reklamacje.jsx" /* webpackChunkName: "component---src-pages-reklamacje-jsx" */),
  "component---src-pages-smart-jsx": () => import("./../../../src/pages/smart.jsx" /* webpackChunkName: "component---src-pages-smart-jsx" */),
  "component---src-pages-technologie-jsx": () => import("./../../../src/pages/technologie.jsx" /* webpackChunkName: "component---src-pages-technologie-jsx" */),
  "component---src-pages-ulubione-jsx": () => import("./../../../src/pages/ulubione.jsx" /* webpackChunkName: "component---src-pages-ulubione-jsx" */),
  "component---src-pages-wszystko-o-drzwiach-jsx": () => import("./../../../src/pages/wszystko-o-drzwiach.jsx" /* webpackChunkName: "component---src-pages-wszystko-o-drzwiach-jsx" */),
  "component---src-pages-wyszukiwarka-jsx": () => import("./../../../src/pages/wyszukiwarka.jsx" /* webpackChunkName: "component---src-pages-wyszukiwarka-jsx" */)
}

