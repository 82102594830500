import React from 'react';
import { paths } from 'utilities/routes';
import { AppProvider } from 'utilities/components/provider/provider';

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element, props }) => (
    // eslint-disable-next-line react/jsx-filename-extension
    <AppProvider { ...props }>{element}</AppProvider>
);

export const shouldUpdateScroll = ({ routerProps: { location }, prevRouterProps }) => {
    const isCurrentLocationArticlesView = location?.pathname.startsWith(paths.artykuly) || location?.pathname.startsWith(paths.wszystkoODrzwiach);
    const isPrevLocationArticlesView = prevRouterProps?.location?.pathname.startsWith(paths.artykuly)
        || location?.pathname.startsWith(paths.wszystkoODrzwiach);

    const getLatestSavedScrollPosition = (pathname) => {
        const partialKey = `@@scroll|${pathname}|`;

        const results = Object.keys(sessionStorage).reduce((acc, key) => {
            // case for articles view (paths.wszystkoODrzwiach)
            if (isCurrentLocationArticlesView || isPrevLocationArticlesView) {
                if (
                    key.startsWith(`@@scroll|${paths.artykuly}/`)
                    || key.startsWith(`@@scroll|${paths.wszystkoODrzwiach}`)
                ) {
                    acc.push(key);
                }
                // case for any other view
            } else {
                if (key.startsWith(partialKey)) {
                    acc.push(key);
                }
            }

            return acc;
        }, []);

        // case when no scroll event ocurred
        if (results.length === 0) {
            return 0;
        }

        // sorgin by timestamp taken from keys in sessionStorage
        const sortedResults = results.sort((a, b) => {
            const aTimestamp = a.split('|');
            const bTimestamp = b.split('|');
            return +bTimestamp[bTimestamp.length - 1] - +aTimestamp[aTimestamp.length - 1];
        });
        const latestScrollPosition = +sessionStorage.getItem(sortedResults[0]);

        return latestScrollPosition;
    };

    // if in artices view (paths.wszystkoODrzwiach) - check and save scroll between tags
    if (isCurrentLocationArticlesView || isPrevLocationArticlesView) {
        window.scroll(0, getLatestSavedScrollPosition(prevRouterProps?.location?.pathname));
        return false;
    }

    if (prevRouterProps) {
        if (prevRouterProps?.location?.pathname === location?.pathname) {
            const prevPosition = getLatestSavedScrollPosition(prevRouterProps?.location?.pathname);
            // keep last position when nothing has changed
            if (prevPosition === 0) {
                return false;
            }

            window.scrollTo(0, prevPosition);

            // false - not update scroll position
            return false;
        }
    }
    return true;
};

export const onServiceWorkerUpdateReady = () => window.location?.reload();
