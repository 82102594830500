import './view.scss';
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import cookiesPolicy from 'assets/docs/cookies-policy.pdf';
import PropTypes from 'prop-types';

const COOKIE_CONSENT_NAME = 'BARANSKI_COOKIE_CONSENT';

export const CookieConsent = ({ children }) => {
    const cookies = new Cookies();
    // default 'true' in cookieConsent prevent from flashing cached component
    const [cookieConsent, setCookieConsent] = useState(true);

    const twoYearsAheadOfNow = new Date(
        new Date().setFullYear(new Date().getFullYear() + 2)
    );
    const acceptCookies = () => {
        setCookieConsent(true);

        cookies.set(COOKIE_CONSENT_NAME, true, {
            expires: twoYearsAheadOfNow
        });
    };

    useEffect(() => {
        setCookieConsent(Boolean(cookies.get(COOKIE_CONSENT_NAME)));
    }, []);

    return (
        <>
            { children }
            {
                !cookieConsent && (
                    <div className="cookie-consent__container">
                        <div className="container__content">
                            <p className="text">
                                W celu świadczenia usług na najwyższym poziomie stosujemy pliki cookies. W każdym momencie mogą Państwo dokonać zmiany ustawień przeglądarki internetowej i wyłączyć opcję zapisu plików cookies. Ze szczegółowymi informacjami można zapoznać się tutaj:
                                {' '}
                                <a
                                    className="policy"
                                    href={ cookiesPolicy }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Polityka ochrony prywatności.
                                </a>
                            </p>
                            <div className="button-wrapper">
                                <button onClick={ acceptCookies } className="button" type="button">Akceptuję</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

CookieConsent.propTypes = {
    children: PropTypes.node.isRequired
};