export const paths = {
    home: '/',
    certyfikaty: '/certyfikaty',
    faq: '/faq',
    gdzieKupic: '/gdzie-kupic',
    historia: '/historia',
    inwestycje: '/inwestycje',
    jakosc: '/jakosc',
    katalogi: '/katalogi',
    kontakt: '/kontakt',
    optimo: '/optimo',
    premium: '/premium',
    produkty: '/produkty',
    produkt: '/produkt',
    akcesorium: '/akcesorium',
    reklamacje: '/reklamacje',
    smart: '/smart',
    styleguide: '/styleguide',
    technologie: '/technologie',
    wszystkoODrzwiach: '/wszystko-o-drzwiach',
    realizacje: '/realizacje',
    drzwiNaWymiar: '/drzwi-na-wymiar',
    doPobrania: '/do-pobrania',
    artykul: '/artykul',
    artykuly: '/artykuly',
    wyszukiwarka: '/wyszukiwarka',
    ulubione: '/ulubione',

    drzwiWewnetrzne: '/produkty/drzwi-wewnetrzne',
    drzwiZewnetrzne: '/produkty/drzwi-zewnetrzne',
    drzwiKlatkoweITechniczne: '/produkty/drzwi-techniczne',
    klamki: '/produkty/klamki'
};

export const routes = {
    home: {
        path: paths.home,
        name: 'Strona główna'
    },
    certyfikaty: {
        path: paths.certyfikaty,
        name: 'Certyfikaty'
    },
    artykul: {
        path: paths.artykul,
        name: 'Artykuł'
    },
    artykuly: {
        path: paths.artykuly,
        name: 'Artykuły'
    },
    faq: {
        path: paths.faq,
        name: 'FAQ'
    },
    gdzieKupic: {
        path: paths.gdzieKupic,
        name: 'Gdzie kupić'
    },
    historia: {
        path: paths.historia,
        name: 'Historia'
    },
    inwestycje: {
        path: paths.inwestycje,
        name: 'Inwestycje'
    },
    jakosc: {
        path: paths.jakosc,
        name: 'Jakość Barańskiego'
    },
    katalogi: {
        path: paths.katalogi,
        name: 'Katalogi'
    },
    kontakt: {
        path: paths.kontakt,
        name: 'Kontakt'
    },
    optimo: {
        path: paths.optimo,
        name: 'Optimo'
    },
    premium: {
        path: paths.premium,
        name: 'Premium'
    },
    klamki: {
        path: paths.klamki,
        name: 'Klamki'
    },
    produkty: {
        path: paths.produkty,
        name: 'Produkty'
    },
    produkt: {
        path: paths.produkt,
        name: 'Produkty'
    },
    reklamacje: {
        path: paths.reklamacje,
        name: 'Reklamacje'
    },
    smart: {
        path: paths.smart,
        name: 'Smart'
    },
    styleguide: {
        path: paths.styleguide,
        name: 'Styleguide'
    },
    technologie: {
        path: paths.technologie,
        name: 'Technologie'
    },
    wszystkoODrzwiach: {
        path: paths.wszystkoODrzwiach,
        name: 'Wszystko o drzwiach'
    },
    realizacje: {
        path: paths.realizacje,
        name: 'Realizacje'
    },
    drzwiNaWymiar: {
        path: paths.drzwiNaWymiar,
        name: 'Drzwi na wymiar'
    },
    doPobrania: {
        path: paths.doPobrania,
        name: 'Do pobrania'
    },
    wyszukiwarka: {
        path: paths.wyszukiwarka,
        name: 'Wyniki wyszukiwania'
    },
    ulubione: {
        path: paths.ulubione,
        name: 'Moje ulubione'
    }
};