/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const availableFilters = {
    brands: [],
    lines: [],
    favorite: false,
    bestseller: false,
    kind: [],
    finishes: [],
    colors: [],
    styles: [],
    glazes: [],
    constructions: [],
    fittings: [],
    destinations: []
};

const initialState = { brand: null, friendlyFilter: null, availableFilters };

export const productFilters = createSlice({
    name: 'productFilters',
    initialState,
    reducers: {
        setBrand: (state, action) => {
            state.brand = action.payload;
        },
        setFriendlyFilter: (state, action) => {
            state.friendlyFilter = action.payload;
        },
        setAvailableFilters: (state, action) => {
            state.availableFilters = action.payload;
        }
    }
});

// actions export
export const { setBrand, setFriendlyFilter, setAvailableFilters } = productFilters.actions;
// state export
export const selectBrand = (state) => state.productFilters.brand;
export const selectFriendlyFilter = (state) => state.productFilters.friendlyFilter;
export const selectAvailableFilters = (state) => state.productFilters.availableFilters;
// exporting the reducer here, as we need to add this to the store
export default productFilters.reducer;
