import { configureStore } from '@reduxjs/toolkit';
import favoritesReducer from 'utilities/redux/favoritesSlice';
import filtersVisibilityReducer from 'utilities/redux/filtersVisibilitySlice';
import productFiltersReducer from 'utilities/redux/productFiltersSlice';

export const store = configureStore({
    reducer: {
        favorites: favoritesReducer,
        filtersVisibility: filtersVisibilityReducer,
        productFilters: productFiltersReducer
    }
});
